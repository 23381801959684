import React from 'react'
import cn from 'classnames'
import { withSiteData } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import RouterLink from '../components/shared/RouterLink'
import PageInfo from '../components/PageInfo'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

export default withSiteData(({ productName }) => (
	<AppLayout>
		<PageInfo
			title={"Page Not Found - " + productName}
		/>
		<div className="tt-screen-content">
			<ContentFor404/>
		</div>
	</AppLayout>
));


const useHomeContentStyles = makeStyles(theme => ({
	mainHeadline: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative",

		[theme.breakpoints.up('md')]: {
			minHeight: "100%",
			flexGrow: "1",
			marginTop: theme.spacing(2),
		},
	},
	mainHeadlineWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		flexGrow: "1",
	},
	mainHeadlineContent: {
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(6),
		// [theme.breakpoints.up('md')]: {
		// 	marginTop: "68px",
		// },
		// [theme.breakpoints.up('lg')]: {
		// 	marginTop: "96px",
		// },
		[theme.breakpoints.up('sm')]: {
			padding: [[0, theme.spacing(6)]],
		},
	},
}), {name: "ContentFor404"});

function ContentFor404() {
	const classes = useHomeContentStyles();

	return (
		<section className={classes.mainHeadline}>
			<div className={classes.mainHeadlineWrapper}>
				<div className={cn(classes.mainHeadlineContent, "container-fluid")}>

					<Typography variant="h1" gutterBottom>
						There was a problem locating that page.
					</Typography>

					<Typography paragraph>
						Either the page you are trying to view does not exist, or there was a problem loading it.
					</Typography>

					<Typography paragraph>
						If you followed a link here, it may be out of date.  <RouterLink to="/">Click here to go home.</RouterLink>
					</Typography>

				</div>
			</div>
		</section>
	);
}
ContentFor404.propTypes = {
};
